<template>
  <div class="container">
    <!-- form -->
    <form @submit.prevent="formSubmission">
      <h1>Isolation des murs</h1>

      <div class="form-group">
        <label for="name">Area (in Meters)</label>
        <input
          type="text"
          class="form-control"
          name="area"
          id="name"
          placeholder="Enter Area in Meters i.e 100"
          v-model="area"
        />
      </div>
      <div class="form-group">
        <label for="region">Select Region</label>
        <input
          type="radio"
          name="region"
          value="ileDeFrance"
          v-model="region"
        />Ile de France <br />
        <input
          type="radio"
          name="region"
          value="AutresRégions"
          v-model="region"
        />Autres régions<br />
      </div>
      <!-- zip code -->
      <div class="form-group">
        <label for="zipcode">Zip Code</label>
        <input
          type="text"
          class="form-control"
          name="zipcode"
          id="zipcode"
          minlength="1"
          maxlength="2"
          placeholder="Enter Zip Code"
          v-model="zipcode"
        />
      </div>
      <div class="form-group">
        <label for="elec">Select Type</label>
        <input
          type="radio"
          name="elec"
          value="combustile"
          v-model="elec"
        />Combustile <br />
        <input type="radio" name="elec" value="elec" v-model="elec" />Elec
        <br />
      </div>
      <div class="form-group">
        <label for="persons">Combien de personnes composent votre foyer</label>
        <input
          type="number"
          min="1"
          max="10"
          name="persons"
          class="form-control"
          v-model="persons"
          id="persons"
          placeholder="Combien de personnes composent votre foyer"
        />
      </div>
      <div class="form-group">
        <label for="revenue">Quels sont les revenus de votre foyer?</label>
        <input
          type="text"
          class="form-control"
          name="revenue"
          id="revenue"
          placeholder="Quels sont les revenus de votre foyer?"
          v-model="revenue"
        />
      </div>
    </form>
    <div class="show">
      <h2>Result</h2>

      <h4>Region</h4>
      <p>{{ findAreaCode(zipcode) }}</p>

      <h4>Total Payment</h4>
      <p>{{ area * 160 + " €" }}</p>

      <h4>Total Benefit</h4>
      <p>{{ results() + " €" }}</p>

      <h4>Pay Total After Benefit</h4>
      <p v-if="results()">{{ area * 160 - results() + " €" }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits",
  data() {
    return {
      area: "",
      persons: "",
      region: "",
      revenue: "",
      elec: "",
      zipcode: "",
      combustile: "",
      elecValue: "",
      areaCodes: [
        {
          id: 1,
          data: [
            "01",
            "02",
            "03",
            "05",
            "08",
            "10",
            "14",
            "15",
            "19",
            "21",
            "23",
            "25",
            "27",
            "28",
            "38",
            "39",
            "42",
            "43",
            "45",
            "51",
            "52",
            "54",
            "55",
            "57",
            "58",
            "59",
            "60",
            "61",
            "62",
            "63",
            "67",
            "68",
            "69",
            "70",
            "71",
            "73",
            "74",
            "75",
            "76",
            "77",
            "78",
            "80",
            "87",
            "88",
            "89",
            "90",
            "91",
            "92",
            "93",
            "94",
            "95",
          ],
        },
        {
          id: 2,
          data: [
            "04",
            "07",
            "09",
            "12",
            "16",
            "17",
            "18",
            "22",
            "24",
            "26",
            "29",
            "31",
            "32",
            "33",
            "35",
            "36",
            "37",
            "40",
            "41",
            "44",
            "46",
            "47",
            "48",
            "49",
            "50",
            "53",
            "56",
            "64",
            "65",
            "72",
            "79",
            "81",
            "82",
            "84",
            "85",
            "86",
          ],
        },
        {
          id: 3,
          data: ["06", "11", "13", "20", "30", "34", "66", "83"],
        },
      ],
    };
  },
  methods: {
    findAreaCode(data) {
      let result = this.areaCodes.find(function (element) {
        return element.data.includes(data);
      });
      if(result){
        if(result.id === 1){
          return "H1";
        }else if(result.id === 2){
          return "H2";
        }else{
          return "H3";
        }
      }
    },
    results() {

            let combustileBlue = 0;
            let combustile = 0;
            let electricBlue = 0;
            let electric = 0;

            let blue = 6096;
            let yellow = 7422;
            let purple = 11455;

      if(this.findAreaCode(this.zipcode) === "H1"){
                    combustileBlue = 50;
                    combustile = 25;
                    electricBlue = 32;
                    electric = 16;
      }else if(this.findAreaCode(this.zipcode) === "H2"){
                    combustileBlue = 41;
                    combustile = 20;
                    electric = 13;
                    electricBlue = 26;
      }else{
                    combustileBlue = 28;
                    combustile = 14;
                    electricBlue = 17;
                    electric = 8;
      }

      if (this.area > 100) {
        var extra = this.area - 100;
        var mainArea = 100;
      } else {
        extra = 0;
        mainArea = this.area;
      }
      if (this.region == "ileDeFrance") {
        if (this.elec == "combustile") {
          if (this.persons == 1) {
            if (this.revenue <= 20593) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 20593 && this.revenue <= 25068) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 25068 && this.revenue <= 38184) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 38184) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 2) {
            if (this.revenue <= 30225) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 30225 && this.revenue <= 36792) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 36792 && this.revenue <= 56130) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 56130) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 3) {
            if (this.revenue <= 36297) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 36297 && this.revenue <= 44188) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44188 && this.revenue <= 67585) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 67585) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 4) {
            if (this.revenue <= 42381) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 42381 && this.revenue <= 51597) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 51597 && this.revenue <= 79041) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 79041) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 5) {
            if (this.revenue <= 48888) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 && this.revenue <= 59026) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 && this.revenue <= 90496) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if(this.persons == 6){
            if (this.revenue <= 48888 + blue) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 + blue && this.revenue <= 59026 + yellow) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 + yellow && this.revenue <= 90496 + purple) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496 + purple) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if(this.persons == 7){
            if (this.revenue <= 48888 + (blue * 2)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 + (blue * 2) && this.revenue <= 59026 + (yellow * 2)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 + (yellow * 2) && this.revenue <= 90496 + (purple * 2) ) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496 + (purple * 2)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if(this.persons == 8){
             if (this.revenue <= 48888 + (blue * 3)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 + (blue * 3) && this.revenue <= 59026 + (yellow * 3)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 + (yellow * 3) && this.revenue <= 90496 + (purple * 3) ) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496 + (purple * 3)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if(this.persons == 9){
             if (this.revenue <= 48888 + (blue * 4)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 + (blue * 4) && this.revenue <= 59026 + (yellow * 4)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 + (yellow * 4) && this.revenue <= 90496 + (purple * 4) ) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496 + (purple * 4)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if(this.persons == 10){
             if (this.revenue <= 48888 + (blue * 5)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 48888 + (blue * 5) && this.revenue <= 59026 + (yellow * 5)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 59026 + (yellow * 5) && this.revenue <= 90496 + (purple * 5) ) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 90496 + (purple * 5)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } 

        } else if (this.elec == "elec") {
          if (this.persons == 1) {
            if (this.revenue <= 20593) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 20593 && this.revenue <= 25068) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 25068 && this.revenue <= 38184) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 38184) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 2) {
            if (this.revenue <= 30225) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 30225) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 36792) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 56130) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 3) {
            if (this.revenue <= 36297) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 36297 && this.revenue <= 44188) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44188 && this.revenue <= 67585) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 67585) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 4) {
            if (this.revenue <= 42381) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 42381 && this.revenue <= 51597) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 51597 && this.revenue <= 79041) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 79041) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 5) {
            if (this.revenue <= 48888) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 && this.revenue <= 59026) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 && this.revenue <= 90496) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
          else if(this.persons == 6){
            if (this.revenue <= 48888 + blue) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 + blue && this.revenue <= 59026 + yellow) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 + yellow && this.revenue <= 90496 + purple) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496 + purple) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if(this.persons == 7){
            if (this.revenue <= 48888 + (blue * 2)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 + (blue * 2) && this.revenue <= 59026 + (yellow * 2)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 + (yellow * 2) && this.revenue <= 90496 + (purple * 2) ) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496 + (purple * 2)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if(this.persons == 8){
             if (this.revenue <= 48888 + (blue * 3)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 + (blue * 3) && this.revenue <= 59026 + (yellow * 3)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 + (yellow * 3) && this.revenue <= 90496 + (purple * 3) ) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496 + (purple * 3)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if(this.persons == 9){
             if (this.revenue <= 48888 + (blue * 4)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 + (blue * 4) && this.revenue <= 59026 + (yellow * 4)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 + (yellow * 4) && this.revenue <= 90496 + (purple * 4) ) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496 + (purple * 4)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if(this.persons == 10){
             if (this.revenue <= 48888 + (blue * 5)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 48888 + (blue * 5) && this.revenue <= 59026 + (yellow * 5)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 59026 + (yellow * 5) && this.revenue <= 90496 + (purple * 5) ) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 90496 + (purple * 5)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } 
        }
      } else if (this.region == "AutresRégions") {
          blue = 4412,
          yellow = 5461,
          purple = 6098

        if (this.elec == "combustile") {
          if (this.persons == 1) {
            if (this.revenue <= 14879) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 14879 && this.revenue <= 19074) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 19074 && this.revenue <= 29148) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 29148) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 2) {
            if (this.revenue <= 21760) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 21760 && this.revenue <= 27896) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 27896 && this.revenue <= 42848) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 42848) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 3) {
            if (this.revenue <= 26170) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 26170 && this.revenue <= 33547) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 33547 && this.revenue <= 51592) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 51592) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 4) {
            if (this.revenue <= 30572) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 30572 && this.revenue <= 39192) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 39192 && this.revenue <= 60336) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 60336) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 5) {
            if (this.revenue <= 34993) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 && this.revenue <= 44860) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 && this.revenue <= 69081) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          } else if (this.persons == 6) {
            if (this.revenue <= 34993 + (blue * 1)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 + (blue * 1) && this.revenue <= 44860 (yellow * 1)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 + (yellow * 1) && this.revenue <= 69081 + (purple * 1)) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081 + (purple * 1)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          }
          else if (this.persons == 7) {
            if (this.revenue <= 34993 + (blue * 2)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 + (blue * 2) && this.revenue <= 44860 (yellow * 2)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 + (yellow * 2) && this.revenue <= 69081 + (purple * 2)) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081 + (purple * 2)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          }
          else if (this.persons == 8) {
            if (this.revenue <= 34993 + (blue * 3)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 + (blue * 3) && this.revenue <= 44860 (yellow * 3)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 + ( yellow * 3 ) && this.revenue <= 69081 + (purple * 3)) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081 + (purple * 3)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          }
          else if (this.persons == 9) {
            if (this.revenue <= 34993 + (blue * 4)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 + (blue * 4) && this.revenue <= 44860 (yellow * 1)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 + (yellow * 4) && this.revenue <= 69081 + (purple * 4)) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081 + (purple * 4)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          }
          else if (this.persons == 10) {
            if (this.revenue <= 34993 + (blue * 5)) {
              return mainArea * (75 + combustileBlue) + extra * combustileBlue;
            } else if (this.revenue > 34993 + (blue * 5) && this.revenue <= 44860 (yellow * 5)) {
              return mainArea * (60 + combustile) + extra * combustile;
            } else if (this.revenue > 44860 + (yellow * 5) && this.revenue <= 69081 + (purple * 5)) {
              return mainArea * (40 + combustile) + extra * combustile;
            } else if (this.revenue > 69081 + (purple * 5)) {
              return mainArea * (0 + combustile) + extra * combustile;
            }
          }
        } else if (this.elec == "elec") {
          if (this.persons == 1) {
            if (this.revenue <= 14879) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 14879 && this.revenue <= 19074) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 19074 && this.revenue <= 29148) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 29148) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 2) {
            if (this.revenue <= 21760) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 21760 && this.revenue <= 27896) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 27896 && this.revenue <= 42848) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 42848) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 3) {
            if (this.revenue <= 26170) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 26170 && this.revenue <= 33547) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 33547 && this.revenue <= 51592) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 51592) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 4) {
            if (this.revenue <= 30572) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 30572 && this.revenue <= 39192) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 39192 && this.revenue <= 60336) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 60336) {
              return mainArea * (0 + electric) + extra * electric;
            }
          } else if (this.persons == 5) {
            if (this.revenue <= 34993) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 && this.revenue <= 44860) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 && this.revenue <= 69081) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081) {
              return mainArea * (0 + electric) + extra * electric;
            } 
          }  else if (this.persons == 6) {
            if (this.revenue <= 34993 + (blue * 1)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 + (blue * 1) && this.revenue <= 44860 (yellow * 1)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 + (yellow * 1) && this.revenue <= 69081 + (purple * 1)) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081 + (purple * 1)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
          else if (this.persons == 7) {
            if (this.revenue <= 34993 + (blue * 2)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 + (blue * 2) && this.revenue <= 44860 (yellow * 2)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 + (yellow * 2) && this.revenue <= 69081 + (purple * 2)) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081 + (purple * 2)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
          else if (this.persons == 8) {
            if (this.revenue <= 34993 + (blue * 3)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 + (blue * 3) && this.revenue <= 44860 (yellow * 3)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 + ( yellow * 3 ) && this.revenue <= 69081 + (purple * 3)) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081 + (purple * 3)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
          else if (this.persons == 9) {
            if (this.revenue <= 34993 + (blue * 4)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 + (blue * 4) && this.revenue <= 44860 (yellow * 1)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 + (yellow * 4) && this.revenue <= 69081 + (purple * 4)) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081 + (purple * 4)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
          else if (this.persons == 10) {
            if (this.revenue <= 34993 + (blue * 5)) {
              return mainArea * (75 + electricBlue) + extra * electricBlue;
            } else if (this.revenue > 34993 + (blue * 5) && this.revenue <= 44860 (yellow * 5)) {
              return mainArea * (60 + electric) + extra * electric;
            } else if (this.revenue > 44860 + (yellow * 5) && this.revenue <= 69081 + (purple * 5)) {
              return mainArea * (40 + electric) + extra * electric;
            } else if (this.revenue > 69081 + (purple * 5)) {
              return mainArea * (0 + electric) + extra * electric;
            }
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  gap: 1rem;
  flex-wrap: wrap;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  max-width: 500px;
  background: lightgoldenrodyellow;
  padding: 1rem;
  border-radius: 10px;
  height: 80vh;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}

form h1 {
  align-self: center;
}
.form-group {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::placeholder {
  color: darkblue;
}

.form-group label {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
.form-group input {
  margin-bottom: 10px;
  border: none;
  background: none;
  border-bottom: 1px solid black;
  padding: 5px;
  text-align: center;
}

.form-group input[type="radio"] {
  margin-bottom: 10px;
  border: none;
  background: none;
  border-bottom: 1px solid black;
  padding: 5px;
  text-align: center;
}

.form-group input[type="number"] {
  margin-bottom: 10px;
  border: none;
  background: none;
  border-bottom: 1px solid black;
  padding: 5px;
  text-align: center;
}

.form-group input:focus {
  outline: none;
}
.form-group :-ms-input-placeholder {
  color: #ccc;
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  background: lightgreen;
  padding: 1rem;
  border-radius: 10px;
  height: 80vh;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}
</style>
